import React from 'react'
import Layout from '../../components/Layout'
import { SEO } from '../../components/seo'
import ClientReviews from '../../components/ClientReviews'
import webDesignBanner from '../../images/web-design-banner.webp'
import howDesignersCanHelp from '../../images/how-designers-can-help.webp'
import whyWebDesignSignificant from '../../images/why-web-design-significant.webp'

const pStyles = {
  fontSize: "18px",
  lineHeight: "1.5",
  letterSpacing: ".5px"
}

const rowPadding = {
  padding: "150px 0"
}

export default function WebDesign() {
  return (
    <Layout>
      <section id='web_design' className='container'>
        <div className='row gx-5 flex-row-reverse' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
                <img src={webDesignBanner} className='img-fluid position-relative w-100' alt='Choose Plan' style={{height: 320, filter: "dropShadow('10px 10px 10px #ababab')"}}></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h1 className='display-5 mb-4'>Web Design</h1>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              As the saying goes “First impressions lasts”, the same applies to your website. Your website’s first impact on your visitors can determine whether they will continue browsing on your products and services or perhaps they will just close your web browser and leave. Our team of experts can help you create a simple, modern, and easy to navigate website that will surely attract your target customers to your website and get desired results.
            </p>
            <a className="btn btn-orange default-padding mt-4 shadow text-capitalize" href="/get-started" role="button">Get started</a>
          </div>
        </div>
      </section>   

      <section className='w-100 web-design parallax text-dark'>
        <div className='container'>
          <div className='row gx-5 justify-content-center text-center' style={rowPadding}>
            <div className='col-12 col-md-10'>
              <p className='text-primary' style={{ fontFamily: "GraphikSuper", letterSpacing: "1px"}}>BRAND IDENTITY</p>
              <h2 className='display-6 mb-4'>Web Design Services that Boost Your Profitability</h2>
              <div className='line-divider mx-auto my-3'></div>
              <p className='mt-4' style={pStyles}>
                Turning website visitors into active buyers, subscribers and customers is our aim with our web design services. We will help you create a website that will make you stand out among your competitors. We also align our web design with your company’s goal and vision. Our unique approach to web design will allow us to create a Design that will work with your line of business and a website design especially just for YOU.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row flex-row-reverse gx-5' style={rowPadding}>
          <div className='col-md-6'>
            <div className='ms-md-5 position-relative'>
              <img src={howDesignersCanHelp} className='img-fluid position-relative w-100' alt='Choose Plan'></img>
            </div>
          </div>
          <div className='col-md-6'>
            <h2 className='display-6 mb-4'>How Our Experts Can Help You</h2>
            <div className='line-divider'></div>
            <p className='mt-4' style={pStyles}>
              Here at Googolweb, our aim is to deliver you the best website design that is affordable, up to date, and results driven. We are focused on achieving great results for your business.
            </p>
            <p style={pStyles}>
              With the use of latest web technology applications and with our experienced web developers and designers, you can be assured that what we deliver is only the best. With our advanced skills and diverse experience, we are truly confident of the amazing work that we will do. If you are ready to take on your business to the digital world to achieve new growth, please get in touch with us!
            </p>
          </div>
        </div>
      </section>

      <section className='w-100 abstract text-dark'>
        <div className='container'>
          <div className='row gx-5' style={rowPadding}>
            <div className='col-md-6'>
              <div className='position-relative'>
                  <img src={whyWebDesignSignificant} className='img-fluid position-relative w-100' alt='Choose Plan'></img>
              </div>
            </div>
            <div className='col-md-6'>
              <h2 className='display-6 mb-4'>Why Is Web Design Significant?</h2>
              <div className='line-divider'></div>
              <p className='mt-4' style={pStyles}>
                Web Design is all about the looks and impression. If you have visited a website that looks untidy, unprofessional, and unfinished; this gives you the impression that the website cannot be trusted, and you would not want to continue viewing it. This is where the significance of web design comes to play. We make sure your website is optimized to be able to get a professional and modern look in all devices, get as many customers as possible for you and most of all get your website noticed by prospective clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row' style={rowPadding}>
          <h2 className='display-6 text-center mb-5'>GoogolWeb Advantage</h2>
          <div className="inline-scrollbox u-scroll-x home-integrations-scrollbox">

            <ul className='inline-scrollbox-wrapper'>
              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Competitive Pricing</h4>
                <p>Offering the most modern, innovative website at a very competitive rate- is the best offer there is!</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Get it Done by the Experts</h4>
                <p>Our experienced designers and developers guarantee you will be completely satisfied with the end results.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'> You are Always In the Know</h4>
                <p> Our Team will work closely with you every step of the way to ensure you are always informed of the progress of your website development.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Transparency of Operations</h4>
                <p>We value open communication and transparency in all our project for we believe a good alliance is founded on trust and openness.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>SEO-Friendly Website</h4>
                <p>We build websites with an SEO advantage to give you a headstart in SERPs.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Security</h4>
                <p>We build advanced security features into each one of the websites that we undertake to protect our client’s businesses from cyber attacks.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Timely Delivery</h4>
                <p>Before we go ahead with your website development, we ensure that we give you a clear and realistic timeline to complete your website.</p>
              </li>

              <li className='home-integrations-item px-4 py-5' style={{height: "296px" , padding: "35px 30px"}}>
                <h4 className='h5 mb-3 text-uppercase'>Customer Satisfaction Always</h4>
                <p>It is a great accomplishment for us when we make our clients happy and satisfied with their website. As our top priority-its is not about us but about YOUR complete satisfaction with our work.</p>
              </li>
          
            </ul>
          </div>
        </div>
      </section>

      <ClientReviews></ClientReviews>
    </Layout>
  )
}

export const Head = () => (
  <SEO title="Website Design" />
)